import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import router from "@/router";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
}

export default function useUnitsList(){

	const refUnitsListTable = ref(null);

	// Table Handlers

	const tableColumns = [
		{ key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
		{ key: 'PropertyTitle', label: i18nT(`Lot`), sortable: true,  thClass: 'pl-1', tdClass: 'pl-1' },
		{ key: 'PropertyTypeID', label: i18nT(`Type`), sortable: true },
		{ key: 'PropertySize', label: i18nT(`Superficie`), sortable: true },
		{ key: 'PropertyRoomsNum' , label: i18nT(`Pièces`), sortable: true },
		{ key: 'PropertyTenants', label: i18nT(`Locataire`), sortable: true },
		{ key: 'PropertyRent', label: i18nT(`Loyer`), sortable: true},
		{ key: 'PropertyPublic', label: i18nT(`Etat`), sortable: true },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true,
		},
	]

	const perPage = ref(10)
	const totalUnits = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const sortBy = ref('')
	const isSortDirDesc = ref(true)
	const searchQuery = ref('')
	const units = ref([])
	const unitLandlord = ref([])
	const unitBuilding = ref([])
	const unitType = ref([])
	const unitOccupied = ref('')
	const listMode = ref('active')
	const activeUnitsCount = ref(0);
	const archiveUnitsCount = ref(0);
	const unitsValue = ref(0);
	const unitsTotalRent = ref(0);
	const unitsRent = ref(0);
	const unitsInitialPrice = ref(0);
	const unitsOccupied = ref(0);
	const unitsUnoccupied = ref(0);
	const unitsMaintenance = ref(0);

	const queries = router.currentRoute.query

	if(queries.currentPage) {
		currentPage.value = queries.currentPage
	}

	if(queries.unitType) {
		unitType.value = queries.unitType.split(',');
	}

	if(queries.unitBuilding) {
		unitBuilding.value = queries.unitBuilding.split(',');
	}

	if(queries.unitLandlord) {
		unitLandlord.value = queries.unitLandlord.split(',');
	}
	if(queries.unitOccupied) {
		unitOccupied.value = queries.unitOccupied
	}

	if(queries.archive){
		listMode.value = 'archive';
	}

	const dataMeta = computed(() => {
		const localItemsCount = refUnitsListTable.value
			? refUnitsListTable.value.localItems.length
			: 0
		return {
			from: perPage.value * (currentPage.value -1 ) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value -1) + localItemsCount,
			of: totalUnits.value,
		}
	})

	let timeoutId = null;
	const refetchData = () => {

		const query = {}

		if( currentPage.value !== 1) {
			query.currentPage = currentPage.value
		}
		if (unitOccupied.value !== '' && unitOccupied.value != '-1') {
			query.unitOccupied = unitOccupied.value
		}
		if(unitType.value.length > 0 && !(unitType.value.includes('-1'))) {
			query.unitType = unitType.value.join(',')
		}

		if(unitBuilding.value.length > 0 && !(unitBuilding.value.includes('-1'))) {
			query.unitBuilding = unitBuilding.value.join(',')
		}
		if(unitLandlord.value.length > 0 && !(unitLandlord.value.includes('-1'))) {
			query.unitLandlord = unitLandlord.value.join(',')
		}
		if(listMode.value == 'archive') {
			query.archive = '1';
		}

		// router.push({query: query})

		const queryString = new URLSearchParams(query).toString();
		if(queryString !== ''){
			history.pushState({}, '', '?' + queryString);
		} else{
			history.pushState({}, '', location.pathname);
		}


		refUnitsListTable.value.refresh()
	}

	watch(
		[
			currentPage,
			perPage,
			unitLandlord,
			unitBuilding,
			unitType,
			unitOccupied,
			listMode,
		],
		() => {
			refetchData()
		}
	)

	watch(
		searchQuery,
		() => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				refetchData()
			}, 500)
		}
	)

	const fetchUnits = (ctx, callback) => {
		const params = {
			page: currentPage.value,
			show_per_page: perPage.value,
			sort_by: sortBy.value,
			sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value,
		}
		//TODO check all filter values/keys with endpoint when ready
		if(unitLandlord.value.length > 0 && !(unitLandlord.value.includes('-1'))) {

			params['filter[LandlordID]'] = unitLandlord.value.join(',')
		}
		if (unitBuilding.value.length > 0 && !(unitBuilding.value.includes('-1'))) {
			params['filter[BuildingID]'] = unitBuilding.value.join(',')
		}
		if (unitType.value.length > 0 && !(unitType.value.includes('-1'))) {
			params['filter[PropertyType]'] = unitType.value.join(',')
		}
		if (unitOccupied.value != '' && unitOccupied.value != '-1') {
			params['filter[Occupied]'] = unitOccupied.value
		}
		if (listMode.value == 'active') {
			params['active'] = 1
		}

		axios.get('landlord/properties', {
			params
		}).then(({data}) => {
			if (data.data.properties) {
				units.value = data.data.properties
				callback(data.data.properties)
			} else {
				units.value = []
				callback([])
			}
			totalUnits.value = data.data.pagination.records_count;
			activeUnitsCount.value = data.data['records_count_active'];
			archiveUnitsCount.value = data.data['records_count_archive'];
			unitsValue.value = data.data['properties_value'];
			unitsTotalRent.value = data.data['properties_renttotal'];
			unitsRent.value = data.data['properties_rent'];
			unitsInitialPrice.value = data.data['properties_initialprice'];
			unitsOccupied.value = data.data['occupied_count'];
			unitsUnoccupied.value = data.data['unoccupied_count'];
			unitsMaintenance.value = data.data['properties_maintenance'];
		}).catch(err => {
			store.commit('app/SET_GLOBAL_MESSAGE', {message: err.response.data['error_message'], type: 'danger'})
		})
	}

	return {
		fetchUnits,
		activeUnitsCount,
		archiveUnitsCount,
		tableColumns,
		perPage,
		currentPage,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUnitsListTable,
		totalUnits,
		listMode,
		units,
		unitLandlord,
		unitBuilding,
		unitType,
		unitOccupied,
		refetchData,
		unitsValue,
		unitsTotalRent,
		unitsRent,
		unitsInitialPrice,
		unitsOccupied,
		unitsUnoccupied,
		unitsMaintenance,
	}
}

