<template>
    <div>
        <v-select
            v-model="tempValue"
            :id="id"
            :autoscroll="autoscroll"
            :clearable="clearable"
            :clearSearchOnBlur="clearSearchOnBlur"
            :clearSearchOnSelect="clearSearchOnSelect"
            :closeOnSelect="closeOnSelect"
            :deselectFromDropdown="deselectFromDropdown"
            :disabled="disabled"
            :filterable="filterable"
            :label="label"
            :loading="loading"
            :multiple="multiple"
            :noDrop="noDrop"
            :options="options"
            :placeholder="placeholder"
            :pushTags="pushTags"
            :reduce="reduce"
            :searchable="searchable"
            :selectable="selectable"
            :selectOnTab="selectOnTab"
            :tabindex="tabindex"
            :taggable="taggable"
            :transition="transition"
            :state="state"
            :value="value"
            @search:blur="onBlur"
            @option:selected="checkSelect"
            @option:deselected="checkDeselect"
            :class='{"rt-state-true": state === true, "rt-state-false": state === false, "rt-placeholder": persistentPlaceholder && persistentPlaceholder.length > 0}'
        >
            <template v-if="persistentPlaceholder && persistentPlaceholder.length > 0" #selected-option-container="{ option, deselect, multiple, disabled }">
                <span 
                    class="d-flex align-items-center pl-1" 
                    v-if="isSelectedOptionVisible(option)"
                >
                    {{ tempValue.length }} 
                    {{ tempValue.length === 1 ? i18nT(persistentPlaceholder[0]) : i18nT(persistentPlaceholder[1])}}
                </span>
            </template>

        </v-select>
    </div>
</template>

<script>

import vSelect from 'vue-select'
import { EventBus } from '@core/utils/event-bus'

export default {
    components: {
        vSelect
    },
    props: {
        autoscroll: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        clearSearchOnBlur: {
            type: Function,
            default: function ({ clearSearchOnSelect, multiple }) {
            return clearSearchOnSelect && !multiple
            }
        },
        clearSearchOnSelect: {
            type: Boolean,
            default: true
        },
        closeOnSelect: {
            type: Boolean,
            default: true
        },
        deselectFromDropdown: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: "label"
        },
        loading: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        noDrop: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default() {
                return [];
            }
        },placeholder: {
            type: String,
            default: ""
        },
        pushTags: {
            type: Boolean,
            default: false
        },
        reduce: {
            type: Function,
            default: option => option,
        },
        searchable: {
            type: Boolean,
            default: true
        },
        selectable: {
            type: Function,
            default: option => true,
        },
        selectOnTab: {
            type: Boolean,
            default: false
        },
        tabindex: {
            type: Number,
            default: null
        },
        taggable: {
            type: Boolean,
            default: false
        },
        transition: {
            type: String,
            default: "fade"
        },
        value: {
            default: null
        },
        state: {
            type: Boolean,
            default: null
        },
        persistentPlaceholder: {
            type: Array,
            default: function () {
                return []
            }
        },
        id: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tempValue: this.multiple ? [] : '',
            removeFilter: false,
        }
    },
    watch: {
        value: {
            handler: function(val) {
                this.tempValue = val
            },
            deep: true
        },
        options: {
            handler: function(newVal, oldVal) {
                if( newVal && newVal.length > 0 && newVal.length !== oldVal.length) {
                    if(this.multiple) {
                        // If tempValue holds a value from the old options array we'll 
                        // emit a 'onDeselct' event to remove it from all filters and
                        // we'll emit a 'inpu' event to update value of the v-model
                        const reducedValue = this.tempValue.filter(value => {
                            const option = this.options.find(option => option.value == value)
                            if(option) {
                                option.originalArray = this.id
                                if(this.icon) {
                                    option.icon = this.icon
                                }
                                EventBus.$emit('onSelect', option)
                                return true
                            }
                            else {
                                const event = {
                                    originalArray: this.id,
                                    value: value
                                }
                                EventBus.$emit('onDeselect', event)
                                return false
                            }
                        })
                        if(reducedValue.length !== this.tempValue.length) {
                            this.tempValue = reducedValue
                            this.$emit('input', reducedValue)
                        }
                    } else {
                        const option = this.options.find(option => option.value == this.tempValue)
                        if(option) {
                            if(this.icon) {
                                option.icon = this.icon
                            }
                            option.originalArray = this.id
                            EventBus.$emit('onSelect', option)
                        }
                    }
                }
            },
            deep: true
        },
        persistentPlaceholder: {
            handler: function(val) {
                if(val && val.length > 0 && !this.multiple) {
                    throw new Error("persistentPlaceholder can be provided only when multiple prop is true");
                }
            },
            deep: true
        }
    },
    created() {

        this.tempValue = this.value
    },
    methods: {
        onBlur() {
            this.$emit('input', this.tempValue)
        },
        checkSelect(event) {
            if(this.multiple) {
                if (event.at(-1).value == '-1') {
                    this.tempValue = []
                    EventBus.$emit('clearSelect', this.id)
                    this.$emit('input', [])
                } else {
                    const option = event.at(-1)
                    if(option) {
                        if(this.icon) {
                            option.icon = this.icon
                        }
                        option.originalArray = this.id
                        EventBus.$emit('onSelect', option)
                    }
                }
            } else {
                if (event.value == '-1') {
                    this.tempValue = []
                    EventBus.$emit('clearSelect', this.id)
                    this.$emit('input', '')
                } else {
                    const option = event
                    if(option) {
                        option.originalArray = this.id
                        if(this.icon) {
                            option.icon = this.icon
                        }
                        option.replace = true
                        EventBus.$emit('onSelect', option)
                        this.$emit('input', event.value)
                    }
                }
            }
        },
        checkDeselect(event) {
            event.originalArray = this.id
            EventBus.$emit('onDeselect', event)
        },
        isSelectedOptionVisible(item) {
            if(typeof(this.tempValue[0]) === 'object') {
                return this.tempValue.indexOf(item) === 0
            } else {
                return this.tempValue.indexOf(item.value) === 0
            }
        },
    }

}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .rt-placeholder.vs--multiple .vs__selected {
        display: none !important;
    }

    .rt-state-true .vs__dropdown-toggle {
        border: 1px solid green
    }
    
    .dark-layout .rt-state-true .vs__dropdown-toggle {
        border: 1px solid green
    }

    .rt-state-false .vs__dropdown-toggle {
        border: 0.5px solid #ea5455;
    }

    .dark-layout .rt-state-false .vs__dropdown-toggle {
        border: 0.5px solid #ea5455;
    }

</style>