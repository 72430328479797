<template>
    <div ref="main-container">
            <!--                FILTERS AND INFO CARDS-->
        <div  class="">
                <div>
                    <b-row class="mb-2">
                        <b-col
                            cols="12"
                            md="4"
                            sm="12"
                        >
                            <h1 class="mb-0">{{i18nT(`Lots`)}}</h1>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            sm="12"
                        >
                            <b-form-group
                            >
                                <b-form-radio-group
                                    v-model="listMode"
                                    button-variant="outline-secondary"
                                    name="radio-btn-outline"
                                    buttons
                                    class="ren-active-buttons"
                                >
                                    <b-form-radio value="active"  >
                                        {{ i18nT(`Actifs`)}}
                                        <b-badge :variant="listMode == 'active' ? 'light-primary' : 'light-secondary' " class="ml-50 text-primary">{{activeUnitsCount}}</b-badge>
                                    </b-form-radio>
                                    <b-form-radio value="archive" >{{i18nT(`Archives`)}}
                                        <b-badge class="ml-50 text-primary" :variant="listMode == 'archive' ? 'light-primary' : 'light-secondary'">{{archiveUnitsCount}}</b-badge>
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                            sm="12"
                        >
                            <b-dropdown
                                variant="primary"
                                class="float-right pl-1 pb-1"
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="plus-circle mr-50" />
                                    {{ i18nT(`Nouveau lot`) }}
                                </template>

                                <b-dropdown-item class="text-primary"
                                                 :to="{ name: 'create-property', params:{tab:'general-info'} }"
                                >
                                    <div class=''>
                                        <RtIcon variant="light" icon="plus-circle mr-50" />
                                        {{ i18nT(`Nouveau lot`) }}
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :to="{name: 'import-tenants'}"
                                >
                                    <div>
                                        <RtIcon variant="light" icon="file-import mr-50" />
                                        {{ i18nT(`Importer`) }}
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-card no-body>
                                <b-card-body class="px-1 py-1 align-items-center">
                                    <div class="d-flex">
                                        <b-avatar size="4em" variant="light-primary">
                                            <RtIcon variant="light" icon="house" :size="20"/>
                                        </b-avatar>
                                        <div v-if="listMode=='active'" class="pl-1">
                                            <h6>{{ unitOccupied === '1' ? i18nT(`LOUÉ`) : i18nT(`LOUÉ / NON LOUÉ`)}}</h6>
                                            <h2
                                                class="m-0 ml-1"
                                            >
                                                <span :class="unitOccupied === '0' ? 'text-secondary': 'text-primary'">{{unitsOccupied}} </span><span v-if="unitOccupied !== '1' ">/ {{unitsUnoccupied}}</span>
                                            </h2>
                                        </div>
                                        <div v-if="listMode=='archive'" class="pl-1">
                                            <h6>{{i18nT(`ARCHIVES`)}}</h6>
                                            <h2
                                                class="m-0 ml-1"
                                            >
                                                <span class="'text-secondary'">{{archiveUnitsCount}}</span>
                                            </h2>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-card no-body>
                                <b-card-body class="px-1 py-1 align-items-center">
                                    <div class="d-flex">
                                        <b-avatar size="4em" variant="light-warning">
                                            <RtIcon variant="light" icon="coins" :size="20"/>
                                        </b-avatar>
                                        <div class="pl-1">
                                            <h6>{{ i18nT(`VALEUR LOCATIVE`) }}</h6>
                                            <h2
                                                class="m-0 text-primary"
                                            >
                                                {{ {amount: unitsRent, currency: 'EUR' } | currency }}
                                            </h2>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>

                        <b-col
                            cols="12"
                            md="4"
                        >
                            <b-card no-body>
                                <b-card-body class="px-1 py-1 align-items-center">
                                    <div class="d-flex">
                                        <b-avatar size="4em" variant="light-info">
                                            <RtIcon variant="light" icon="chart-mixed-up-circle-dollar" :size="20"/>
                                        </b-avatar>
                                        <div class="pl-1">
                                            <h6>{{ i18nT(`VALEUR DES ACTIFS`) }}</h6>
                                            <h2
                                                class="m-0 text-primary"
                                            >
                                                {{ {amount: unitsValue, currency: 'EUR' } | currency }}
                                            </h2>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <b-card no-body>
            <b-card-body>
            <div>
                <b-row class="">
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Propriétaires`)">
                            <rt-select
                                v-model="unitLandlord"
                                :options="unitLandlords"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :placeholder="i18nT(`Tous les propriétaires`)"
                                :closeOnSelect="false"
                                multiple
                                deselectFromDropdown
                                :persistentPlaceholder="['Propriétaire', 'Propriétaires']"
                                :reduce="(e) => e.value"
                                id='unitLandlord'
                                icon="user-check"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Immeubles`)">
                            <rt-select
                                v-model="unitBuilding"
                                :options="unitBuildings"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :placeholder="i18nT(`Tous les immeubles`)"
                                :closeOnSelect="false"
                                multiple
                                deselectFromDropdown
                                :persistentPlaceholder="['Immeuble', 'Immeubles']"
                                :reduce="(e) => e.value"
                                id='unitBuilding'
                                icon="building"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Type`)">
                            <rt-select
                                v-model="unitType"
                                :options="unitTypes"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :placeholder="i18nT(`Tous les types`)"
                                :closeOnSelect="false"
                                multiple
                                deselectFromDropdown
                                :persistentPlaceholder="['Type', 'Types']"
                                :reduce="(e) => e.value"
                                id='unitType'
                                icon="door-open"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="3"
                    >
                        <b-form-group :label="i18nT(`Location`)">
                            <rt-select
                                v-model="unitOccupied"
                                :options="unitVacancies"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :clearable="false"
                                :placeholder="i18nT(`Avec et sans location`)"
                                :reduce="(e) => e.value"
                                id="unitOccupied"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        sm="3"
                        v-if="false"
                        class="d-flex align-items-center"
                    >
                        <b-button
                            class="mt-1"
                            variant="outline-danger"
                            size="md"
                            @click="clearFilters"
                            v-b-tooltip.hover.top="i18nT(`Supprimer les filtres`)"
                        >
                            <feather-icon icon="XCircleIcon"/>
                            <!-- {{ i18nT(`Supprimer les filtres`) }} -->
                        </b-button>
                        <!-- <feather-icon class="text-danger" size="2x" icon="XCircleIcon"/> -->
                    </b-col>
                    <div class="ml-50">
                        <rt-all-filters @removeFilter="removeFilter" @clearFilters="clearFilters"/>
                    </div>
                </b-row>
            </div>
            </b-card-body>
        </b-card>
            <!--                TABLE AND SEARCH-->
        <b-card no-body>
            <div>
                <b-row class="mx-2 mt-2">
                    <b-col
                        cols="12"
                        md="7"
                        sm="6"
                        class="mb-1 pl-0"
                    >
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            v-if="selectedItems.length > 0"
                            class="d-inline ml-1"
                            size="md"
                            text="Actions"
                            variant="outline-secondary"
                        >
                            <b-dropdown-item @click="onBulkArchive()">
                                <span class="align-middle">
                                    <RtIcon :size="16" variant="light" icon="box-archive"/>
                                </span>
                                <span class="align-middle ml-50">{{ listMode === 'active' ? i18nT(`Archiver la sélection`) : i18nT(`Sortir des archives`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onBulkDelete()">
                                <span class="text-danger"><RtIcon :size="16" variant="light" icon="trash"/></span>
                                <span
                                    class="align-middle ml-50 text-danger"
                                >{{
                                        i18nT(`Supprimer`)
                                    }}</span>
                            </b-dropdown-item>
                        </b-dropdown>

                    </b-col>
<!--                            search-input-->
                    <b-col
                        cols="12"
                        md="5"
                        sm="6"
                        class="mb-1 pr-0 d-flex justify-content-end"
                    >
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div class="input-group input-group-merge" role="group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <RtIcon variant="light" icon="search"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Chercher`)"
                                        class="d-inline-block"
                                        type="text"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <b-table
                    ref="refUnitsListTable"
                    v-model="currentItems"
                    :key="'UnitsTable' + tableColumns.length"
                    :empty-html="emptyHtml"
                    :fields="computedColumns"
                    :items="fetchUnits"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    primary-key="id"
                    responsive
                    show-empty
                    @refreshed="onTableRefresh"
                >
            <!--                        TODO - add template cells-->

                    <template v-slot:head(checkbox)="data">
                        <span>{{data.label.toUpperCase()}}</span>
                        <b-form-checkbox
                            v-model="selectAllStatus"
                            @change="onSelectAll"
                            />
                    </template>
                    <template #head(action)="data">
                        <span>{{data.label.toUpperCase()}}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="true"
                                v-b-tooltip="i18nT(`Colonnes`)"
                            >
                                <template #button-content>
                                    <RtIcon variant="light" icon="gear text-body ren-lh-22" size="18px"/>
                                </template>

                                <div class="pl-1 pr-1"
                                     v-for="(field, index) in availableFields"
                                     :key="'Units - ' + index"
                                >
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{i18nT(field.label)}}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>

                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-model="selectedItems"
                            :value="data.item.PropertyID"
                            class="pl-0"
                        />
                    </template>
                    <!--                                        TODO add PropertyDefaultHexColor-->

                    <template #cell(PropertyTitle)="data">
                        <div class="media">
                            <div class="media-aside align-self-center">
                                <b-avatar
                                    :src="getCoverImage(data.item.PropertyImages)"
                                    :text="`${data.item.PropertyTitle.slice(0,2)}`"
                                    badge-variant="primary"

                                    :style="{
                                        backgroundColor: '#' + (data.item.PropertyAvatarHexColor ? data.item.PropertyAvatarHexColor : data.item.PropertyDefaultHexColor),
                                        boxShadow: '0 0 0 2px white, 0 0 0 4px #' + (data.item.PropertyAvatarHexColor ? data.item.PropertyAvatarHexColor : data.item.PropertyDefaultHexColor),
                                        color:'white'
                                    }"
                                >
                                </b-avatar>
                            </div>
                            <div class="media-body">
                                <b-link
                                    class="mb-0 font-weight-bold d-block text-nowrap ren-link"
                                    :to="{name:'view-property', params:{id: data.item.PropertyID}}"
                                >
                                    <span v-b-tooltip.hover.topleft.html="tipData(data.item)">
                                        {{ data.item.PropertyTitle }}
                                    </span>
                                </b-link>
                                <div class="d-flex flex-column">
                                    <small class="text-secondary" v-for="(building, index) in data.item.PropertyBuildings" :key="'building'+ index">
                                        <RtIcon variant="light pr-25" icon="building"/>
                                        {{ building.label }}
                                    </small>
                                    <small class="text-secondary">
                                        <RtIcon variant="light pr-25" icon="location-dot"/>
                                        {{ data.item.PropertyAddress }}
                                        {{ data.item.PropertyPostalCode }}
                                        {{ data.item.PropertyCity }}
                                        {{ data.item.PropertyCountry }}
                                    </small>
                                    <small class="text-secondary" v-if="false">
                                        <RtIcon variant="light pr-25 text-warning" icon="triangle-exclamation"/>
                                        {{ i18nT(`Assurance propriétaire expirée`) }}
                                    </small>
                                    <small class="text-secondary" v-if="data.item.PropertyID%3==0">
                                        <RtIcon variant="light pr-25 text-warning" icon="triangle-exclamation"/>
                                        {{ i18nT(`Diagnostics expirés`) }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template #cell(PropertyTypeID)="data">
                        <p class="m-0">{{getTypeName(data.item.PropertyTypeID)}}</p>
                    </template>

                    <template #cell(PropertySize)="data">
                        <p class="m-0">{{data.item.PropertySize ? data.item.PropertySize : '0.00'}}</p>
                    </template>

                    <template #cell(PropertyRoomsNum)="data">
                        <p class="m-0">{{data.item.PropertyRoomsNum ? data.item.PropertyRoomsNum : 'N/A'}}</p>
                    </template>

                    <template #cell(PropertyTenants)="data">
                        <div class="d-flex">
                            <b-link v-if="data.item.PropertyTenants != null"
                                 v-for="(tenant, index) in data.item.PropertyTenants"
                                 :key="'tenant'+index"
                                 class="mr-1 d-flex cursor-pointer text-nowrap"
                                 :to="{name:'view-tenant', params:{id: tenant.id}}"
                            >
                                {{tenant.label}}
                            </b-link>
                            <b-link
                                v-if="!data.item.PropertyTenants"
                                variant="primary"
                                class="btn btn-outline-secondary btn-sm"
                                :to="{name: 'create-lease', params: {tab: 'general-info'}, query:{propertyId:data.item.PropertyID}}"
                            >
                                {{ i18nT(`Créer une location`) }}
                            </b-link>
                        </div>
                    </template>

                    <template #cell(PropertyRent)="data">
                        <h5 class="m-0"
                            :class="!data.item.PropertyRent? '' : parseFloat(data.item.PropertyRent) < 0 ? 'text-danger' : 'text-success'"
                        >
                            {{ {amount: data.item.PropertyRent, currency: 'EUR' } | currency }}
                        </h5>
                    </template>

                    <template #cell(PropertyPublic)="data">
                        <b-badge
                            :variant="data.item.PropertyPublic == 1 ? 'light-success' : 'light-secondary'"
                            class="badge-pill p-50 cursor-pointer"
                            v-b-tooltip="data.item.PropertyPublic == 1 ? i18nT(`Flyer numerique Public`) : i18nT(`Flyer numerique privé`)"
                        >
                            {{data.item.PropertyPublic == 1 ? i18nT(`Public`) : i18nT(`Privé`)}}
                        </b-badge>
                    </template>

                    <template #cell(action)="data">
                        <b-link
                            :to="{name:'edit-property', params:{id: data.item.PropertyID}}"
                            class="text-secondary"
                            v-b-tooltip.hover.top="i18nT(`Modifier`)"
                        >
                            <RtIcon variant="light" icon="edit mr-1" :size="15"/>
                        </b-link>
                        <b-link
                            :to="{name:'view-property', params:{id: data.item.PropertyID}}"
                            class="text-secondary"
                            v-b-tooltip.hover.top="i18nT(`Voir`)"
                        >
                            <RtIcon variant="light" icon="eye mr-1" :size="15"/>
                        </b-link>
                        <b-dropdown
                            no-caret
                            toggle-class="p-0"
                            variant="link"
                            dropleft
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                            </template>
                            <b-dropdown-item
                                :to="{name:'edit-property', params:{id: data.item.PropertyID}}"
                            >
                                <RtIcon variant="light" icon="edit" />
                                <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onArchive(data.item)"
                            >
                                <RtIcon variant="light" icon="box-archive"/>
                                <span class="align-middle ml-50">
                                    {{listMode === 'active' ? i18nT(`Archiver`) : i18nT(`Désarchiver`)}}
                                </span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onDelete(data.item)"
                            >
                                <RtIcon variant="light" icon="trash text-danger"/>
                                <span class="align-middle ml-50 text-danger">
                                    {{i18nT(`Supprimer`)}}
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
            </div>
            <div class="mx-1 mb-2">
                <b-row v-if="units.length > 0">
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="4"
                    >
                        <b-form-checkbox
                            v-model="selectAllStatus" class="d-inline-block mx-1"
                            @change="onSelectAll"
                        >
                            {{ i18nT(`Tout`)}}
                        </b-form-checkbox>
                        <b-dropdown
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="download"/>
                                {{ i18nT(`Export`) }}
                            </template>
                            <b-dropdown-item class="text-primary"
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-excel"/>
                                    <span class="pl-1">
                                {{ i18nT(`Export format Excel`) }}
                                    </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-lines"/>
                                    <span class="pl-1">{{ i18nT(`Export format OpenOffice`) }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                        <span class="text-muted ml-1 d-none d-md-flex text-nowrap">
                        {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                        {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                        {{ i18nT(`entrées`) }}
                        </span>
                    </b-col>
<!--                        Pagination-->
                    <b-col
                        cols="12"
                        sm="4"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalUnits"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                        >
                            <template #prev-text>
                                <RtIcon variant="light" icon="chevron-left" :size="16"/>
                            </template>
                            <template #next-text>
                                <RtIcon variant="light" icon="chevron-right" :size="16"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

<!--        FLOAT ACTIONS-->
        <b-card no-body v-if="selectedItems.length>0" class="float-actions">
            <b-row class="">
                <b-col cols="12"
                       class="d-flex m-1 "
                >
                    <span class="d-none d-md-flex align-items-center mr-1">
                    {{i18nT(`Actions`)}}
                        </span>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Supprimer la sélection`)"
                        @click="onBulkDelete"
                    >
                        <RtIcon :size="16" variant="light" icon="trash"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "listMode === 'active' ? i18nT(`Archiver la sélection`) : i18nT(`Sortir des archives`)"
                        @click="onBulkArchive"
                    >
                        <RtIcon :size="16" variant="light" icon="box-archive"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>


<script>

import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardImg,
    BCardGroup,
    BFormGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,
    BProgress,
    VBTooltip,
    BButton,
    BAvatar,
    BLink,
} from 'bootstrap-vue'
import useUnitsList from "@/views/landlord/Properties/Units/tableUnitsList";
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut.vue";
import {dictToSelectArray} from "@core/utils/utils";
import RtSelect from '@core/components/RtSelect.vue';
import RtAllFilters from '@core/components/RtAllFilters.vue';
import Ripple from 'vue-ripple-directive'
import useAuth from "@/auth/useAuth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from '@/store/index'


export default {
    components: {
        BButton,
        AppEchartDoughnut,
        BDropdownItem,
        BDropdown,
        BFormCheckbox,
        BFormRadioGroup,
        BFormGroup,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardHeader,
        BCardFooter,
        BCardTitle,
        BCardSubTitle,
        BCardText,
        BCardImg,
        BCardGroup,
        vSelect,
        VueApexCharts,
        AppEchartBar,
        BProgress,
        RtSelect,
        RtAllFilters,
        BAvatar,
        BLink,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    setup() {
        const {
            fetchUnits,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUnitsListTable,
            totalUnits,
            listMode,
            units,
            unitLandlord,
            unitBuilding,
            activeUnitsCount,
            archiveUnitsCount,
            unitType,
            unitOccupied,
            unitsValue,
            unitsTotalRent,
            unitsRent,
            unitsInitialPrice,
            unitsOccupied,
            unitsUnoccupied,
            unitsMaintenance,
            refetchData,
        } = useUnitsList()


        return {
            fetchUnits,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUnitsListTable,
            totalUnits,
            listMode,
            units,
            unitLandlord,
            unitBuilding,
            unitType,
            activeUnitsCount,
            archiveUnitsCount,
            unitOccupied,
            unitsValue,
            unitsTotalRent,
            unitsRent,
            unitsInitialPrice,
            unitsOccupied,
            unitsUnoccupied,
            unitsMaintenance,
            refetchData,
        }
    },
    data(){
        return{
            unitTypes: [],
            unitLandlords: [],
            unitBuildings: [],
            unitVacancies: [],
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            availableFields:[
                {label: this.i18nT(`Type`), key: 'PropertyTypeID', visible: true},
                {label: this.i18nT(`Superficie`), key: 'PropertySize', visible: true},
                {label: this.i18nT(`Pièces`), key: 'PropertyRoomsNum', visible: true},
                {label: this.i18nT(`Locataire`), key: 'PropertyTenants', visible: true},
                {label: this.i18nT(`Loyer`), key: 'PropertyRent', visible: true},
                {label: this.i18nT(`Etat`), key: 'PropertyPublic', visible: true},
            ],

        }
    },
    created(){


        let storePreferences = useAuth.getColumnPreferences('lots')
        if(storePreferences) {
            this.availableFields = storePreferences
        }

        this.$http.get('landlord/properties/filters').then(({data})=>{

            this.unitLandlords = dictToSelectArray(data.data.landlords)
            this.unitLandlords.unshift({
                value: '-1',
                text: this.i18nT(`Tout`)
            })

            this.unitBuildings = dictToSelectArray(data.data.buildings)
            this.unitBuildings.unshift({
                value: '-1',
                text: this.i18nT(`Tout`)
            })

            this.unitTypes = dictToSelectArray(data.data['property_types'])
            this.unitTypes.unshift({
                value: '-1',
                text: this.i18nT(`Tout`)
            })

            this.unitVacancies = data.data['ocupation_types'].map((item,index)=>{
                return {
                    value: ''+index,
                    text: item
                }
            })
            this.unitVacancies.unshift({
                value: '-1',
                text: this.i18nT(`Tout`)
            })

        })
    },
    computed: {
        //TODO change text and route
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici ...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer les biens immobiliers.`) + "</p>"
                +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" +"properties/create-property/general-info\"'>" + this.i18nT(`Créer un bien`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.availableFields.length; i++) {
                if(!this.availableFields[i].visible) {
                    col = col.filter(c => c.key !== this.availableFields[i].key)
                }
            }
            useAuth.setColumnPreferences('lots', this.availableFields);

            return col;
        },

    },
    methods: {
        onTableRefresh() {
            this.selectedItems = []
        },
        onSelectAll(val) {
           if(val){
               this.selectedItems = this.currentItems.map(item => item.PropertyID);
           }else{
               this.selectedItems = []
           }
        },
        onArchive(item) {
            const type = this.listMode === 'active' ? 'archive' : 'unarchive'

            this.$http.post(`landlord/properties/${type}?id=${item.PropertyID}`)
                .then(() => {
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onDelete(item) {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression de ce bien. Cette action entrainera la suppression de tous les données qui lui sont liés.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.delete(`landlord/properties?id=${item.PropertyID}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkArchive() {
            const idsString = this.selectedItems.join(',')
            const type = this.listMode === 'active' ? 'massArchive' : 'massUnarchive'

            this.$http.post(`landlord/properties/${type}?ids=${idsString}`)
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })


        },
        onBulkDelete() {
            const idsString = this.selectedItems.join(',')

            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http.post(`landlord/properties/massDelete?ids=${idsString}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false;
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.selectedItems = []
                        this.selectAllStatus = false;
                        this.refetchData()
                    })
                }
            })
        },
        clearFilters(){
            this.unitLandlord = [];
            this.unitBuilding = [];
            this.unitType = [];
            this.unitOccupied = '';
        },
        removeFilter(filter) {
            const multiple = typeof(this[filter.originalArray]) == 'object'

            if(!multiple) {
                this[filter.originalArray] = ''
            } else {
                this[filter.originalArray] = this[filter.originalArray].filter(item => item !== filter.value)
            }
        },
        getCoverImage(imgArr){
            if(imgArr.length === 0){
                return ''
            }
            const getCoverImage = imgArr.find(img=>img.PropertyCoverImage === 1)

            if(getCoverImage){
                return getCoverImage['image_thumb_url']
            }else{
                return imgArr[0]['image_thumb_url']
            }

        },
        getTypeName(id){
            const type = this.unitTypes.find(item => item.value == id)
            return type ? type.text : ''
        },
        tipData(property){

            const typeName = this.getTypeName(property.PropertyTypeID);
            const typeTitle = this.i18nT(`Type`);
            const size = property.PropertySize;
            const sizeTitle = this.i18nT(`Superficie`);
            const rooms = property.PropertyRoomsNum ;
            const roomsTitle = this.i18nT(`Pièces`);
            const chambres = property.PropertyRoomsNumChambres;
            const chambresTitle = this.i18nT(`Chambres`);
            const statusTitle = this.i18nT(`Flyer numérique`);
            const status = property.PropertyPublic == 1 ? this.i18nT(`Public`) : this.i18nT(`Privé`);
            const statusClass = property.PropertyPublic == 1 ? 'success' : 'secondary';
            const rent = parseFloat(property.PropertyRent).toFixed(2);
            const rentTitle = this.i18nT(`Loyer HC`);
            const charges = property.PropertyMaintenance;
            const chargesTitle = this.i18nT(`Charges`);


            return `
                <div class="tooltip-container">
                    <div class="tooltip-text-container">
                        ${typeName ?`<p class="tooltip-text"><b>${typeTitle}:</b>&nbsp;&nbsp;${typeName}</p>` : ''}
                        ${size ? `<p class="tooltip-text"><b>${sizeTitle}:</b>&nbsp;&nbsp;${size} m²</p>` : ''}
                        ${rooms ? `<p class="tooltip-text"><b>${roomsTitle}:</b>&nbsp;&nbsp;${rooms}</p>` : ''}
                        ${chambres ? `<p class="tooltip-text"><b>${chambresTitle}:</b>&nbsp;&nbsp;${chambres}</p>` : ''}
                        ${rent ? `<p class="tooltip-text"><b>${rentTitle}:</b>&nbsp;&nbsp;€ ${rent}</p>` : ''}
                        ${charges ? `<p class="tooltip-text"><b>${chargesTitle}:</b>&nbsp;&nbsp;€ ${charges}</p>` : ''}
                        <p class="tooltip-text"><b>${statusTitle}:</b> &nbsp;&nbsp;<span class="tooltip-badge bg-${statusClass}">${status}</span></p>
                    </div>
                </div>
            `;
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


.ren-info-card-icon{
    background-color: rgba(0, 207, 232, 0.2);
    border-radius: 15%;
    padding: 10px;
}

.ren-filter-badge {
    padding: 10px;
    margin: 5px;
}

.sk-column-filter{
    width: 50px;
}

.ren-link[href="#"] {
    color: inherit; /* inherit color from parent */
}

.ren-link[href="#"]:hover {
    color: inherit !important; /* Keeps the color same on hover */
    cursor: default !important;
}



// Tenants column
.ren-tenant {
    // color: #8a8afc !important;
    //text-decoration: underline;
}


</style>