<template>
    <b-row>
        <b-col cols="12" v-if="allFilters.length > 0">
            <b-badge
                variant="light-primary"
                class="cursor-pointer ren-filter-badge "
                v-for="(filter, index) in allFilters"
                @click="removeFilter(filter)"
                :key="'filter' + index"
            >
                <div>
                    <RtIcon 
                        v-if="filter.icon"
                        variant="light"
                        :icon=" filter.icon" 
                    />
                    {{ filter.text }}
                    <RtIcon variant="light" icon="circle-xmark" />

                    
                </div>
            </b-badge>
            <b-badge
                class="cursor-pointer ren-filter-badge"
                variant="light-danger"
                @click="clearFilters"
            >
                <RtIcon variant="light" icon="circle-xmark" />
                {{ i18nT(`Supprimer les filtres`) }}
            </b-badge>
        </b-col>
    </b-row>
</template>

<script>

import {
    BRow,
    BCol,
} from "bootstrap-vue";
import { EventBus } from '@core/utils/event-bus'

export default {
    components: {
        BRow,
        BCol
    },
    data() {
        return {
            allFilters: []
        }
    },
    created() {
        EventBus.$on('onSelect', this.addToAllFilters)
        EventBus.$on('onDeselect', this.removeFromAllFilters)
        EventBus.$on('clearSelect', this.clearFromAllFilters)
    },
    destroyed() {
        EventBus.$off('onSelect', this.addToAllFilters)
        EventBus.$off('onDeselect', this.removeFromAllFilters)
        EventBus.$off('clearSelect', this.clearFromAllFilters)
    },
    methods: {
        addToAllFilters(value) {
            if(!value.replace) {
                const elementInAllFilters = this.allFilters.find(item => {
                    if(item.originalArray == value.originalArray && item.value == value.value) {
                        return true
                    }
                })
                if(elementInAllFilters == undefined) {
                    this.allFilters.push(value)
                }
            } else {
                const index = this.allFilters.findIndex(item => item.originalArray === value.originalArray)
                if(index != -1) {
                    this.allFilters.splice(index, 1, value)
                } else {
                    this.allFilters.push(value)
                }
            }
        },
        removeFromAllFilters(value) {
            this.allFilters = this.allFilters.filter(item => {
                if(item.originalArray != value.originalArray) {
                    return true
                }
                if (item.value != value.value) {
                    return true
                }
            })
        },
        clearFromAllFilters(originalArray) {
            this.allFilters = this.allFilters.filter(item => item.originalArray !== originalArray)
        },
        removeFilter(filter) {
            this.allFilters = this.allFilters.filter(item => item.value !== filter.value)
            this.$emit('removeFilter', filter)
        },
        clearFilters() {
            this.allFilters = []
            this.$emit('clearFilters')
        }
    }
}

</script>
